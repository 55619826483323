var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cart-item", staticStyle: { position: "relative" } },
    [
      _c(
        "div",
        {
          staticClass: "line-item d-flex flex-row",
          class: { dashed: _vm.dashed },
        },
        [
          _c(
            "div",
            { staticClass: "product-name-container" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.displayProductName(_vm.item)) +
                  "\n      "
              ),
              _vm.isFreeTrial
                ? _c("span", [
                    _vm._v(
                      " - Free Trial (" +
                        _vm._s(_vm.item.display_info.trial_duration_days) +
                        " days)"
                    ),
                  ])
                : _vm._e(),
              _vm.itemState &&
              !_vm.displayProductName(_vm.item).includes(`${_vm.itemState}`)
                ? _c("span", [_vm._v(" in " + _vm._s(_vm.itemState))])
                : _vm._e(),
              _vm.cartItemIsOverseasShippingFee(_vm.item)
                ? _c("fa-icon", {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.right",
                        value: _vm.overseasShippingFeeProductDescription,
                        expression: "overseasShippingFeeProductDescription",
                        modifiers: { hover: true, right: true },
                      },
                    ],
                    staticClass: "question-circle pl-1 help-text",
                    attrs: { icon: "question-circle", size: "1x" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.dashed ? _c("hr", { staticClass: "line-of-dashed" }) : _vm._e(),
          _c("div", { staticClass: "price-container" }, [
            _vm.item.product_type === "LegalSpec"
              ? _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.item.data.estimated_cost) +
                      "\n      "
                  ),
                ])
              : _c("div", [
                  _vm.isFreeTrial
                    ? _c("span", { staticClass: "vac-text-strike" }, [
                        _c("s", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("dollars")(_vm.item.total)) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm.productIsBundle(_vm.item)
                    ? _c("span", [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("dollars")(
                                  _vm.productBundleTotal(_vm.item)
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm.item.product_type === "PromoCode"
                    ? _c("span", { staticClass: "promo-code" }, [
                        _vm._v(
                          "\n          - " +
                            _vm._s(_vm._f("dollars")(-_vm.item.total)) +
                            "\n        "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("dollars")(_vm.item.total)) +
                            "\n        "
                        ),
                      ]),
                  _vm.isMonthly
                    ? _c("span", [_c("br"), _vm._v(" Monthly")])
                    : _vm._e(),
                ]),
            _c(
              "div",
              { staticClass: "remove-button-container" },
              [
                !_vm.item.parent_item_id
                  ? _c(
                      "b-link",
                      {
                        staticClass: "m-0 p-0 remove-button",
                        attrs: { "aria-label": "remove item link" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.removeItem.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          attrs: { type: "x", width: 16, height: 16 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.productIsBundle(_vm.item) && _vm.showSubItems
        ? _c(
            "div",
            { staticClass: "mt-1" },
            [
              _vm.item.display_info.products
                ? _c(
                    "ul",
                    { staticClass: "product-sub-items-list" },
                    _vm._l(_vm.item.display_info.products, function (subItem) {
                      return _c("li", { key: subItem.id }, [
                        _c("small", [_vm._v(_vm._s(subItem.name))]),
                      ])
                    }),
                    0
                  )
                : _vm.item.data
                ? _c(
                    "ul",
                    {
                      staticClass: "product-sub-items-list",
                      class: { dashed: _vm.dashed },
                    },
                    _vm._l(
                      _vm.productBundleSubItems,
                      function (subItem, subItemId) {
                        return _c("li", { key: subItemId }, [
                          _c(
                            "div",
                            {
                              staticClass: "line-item d-flex flex-row",
                              class: { dashed: _vm.dashed },
                            },
                            [
                              _c("small", { staticClass: "product-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(subItem.name) +
                                    "\n          "
                                ),
                              ]),
                              _vm.dashed
                                ? _c("hr", { staticClass: "line-of-dashed" })
                                : _vm._e(),
                              _c(
                                "small",
                                {
                                  staticClass: "product-price",
                                  class: _vm.itemFree(subItem)
                                    ? "strike-through-product-cost"
                                    : "",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm._f("dollars")(subItem.price)) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._l(
                _vm.productBundleFilingMethods,
                function (filingMethod, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "line-item d-flex flex-row",
                      class: { dashed: _vm.dashed },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n        " + _vm._s(filingMethod.name) + "\n      "
                        ),
                      ]),
                      _vm.dashed
                        ? _c("hr", { staticClass: "line-of-dashed" })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm._f("dollars")(filingMethod.price)) +
                            "\n      "
                        ),
                      ]),
                    ]
                  )
                }
              ),
            ],
            2
          )
        : _vm._e(),
      _vm.item.display_info.product_category === "free form a company"
        ? _c(
            "div",
            { staticClass: "d-flex flex-row justify-content-between" },
            [
              _c("div", [
                _vm._v("\n      6 Month Registered Agent Service "),
                _vm.itemState
                  ? _c("span", [_vm._v(" in " + _vm._s(_vm.itemState))])
                  : _vm._e(),
              ]),
              _c("div", [
                _vm._v("\n      " + _vm._s(_vm._f("dollars")(0)) + "\n    "),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }