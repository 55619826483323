<template>
  <div class="cart-item" style="position: relative;">
    <div class="line-item d-flex flex-row" :class="{ dashed }">
      <div class="product-name-container">
        {{ displayProductName(item) }}
        <span v-if="isFreeTrial"> - Free Trial ({{ item.display_info.trial_duration_days }} days)</span>
        <span v-if="itemState && !displayProductName(item).includes(`${itemState}`)"> in {{ itemState }}</span>
        <fa-icon
          v-if="cartItemIsOverseasShippingFee(item)"
          v-b-popover.hover.right="overseasShippingFeeProductDescription"
          class="question-circle pl-1 help-text"
          icon="question-circle"
          size="1x"
        />
      </div>

      <hr v-if="dashed" class="line-of-dashed">

      <div class="price-container">
        <div v-if="item.product_type === 'LegalSpec'">
          {{ item.data.estimated_cost }}
        </div>
        <div v-else>
          <span v-if="isFreeTrial" class="vac-text-strike">
            <s>
              {{ item.total | dollars }}
            </s>
          </span>
          <span v-else-if="productIsBundle(item)">
            <span>
              {{ productBundleTotal(item) | dollars }}
            </span>
          </span>
          <span v-else-if="item.product_type === 'PromoCode'" class="promo-code">
            - {{ -item.total | dollars }}
          </span>
          <span v-else>
            {{ item.total | dollars }}
          </span>

          <span v-if="isMonthly"><br> Monthly</span>
        </div>

        <div class="remove-button-container">
          <b-link
            v-if="!item.parent_item_id"
            class="m-0 p-0 remove-button"
            aria-label="remove item link"
            @click.stop="removeItem"
          >
            <feather-icon type="x" :width="16" :height="16" />
          </b-link>
        </div>
      </div>
    </div>

    <!-- Bundle Items-->
    <div v-if="productIsBundle(item) && showSubItems" class="mt-1">
      <ul v-if="item.display_info.products" class="product-sub-items-list">
        <li v-for="subItem in item.display_info.products" :key="subItem.id">
          <small>{{ subItem.name }}</small>
        </li>
      </ul>
      <ul v-else-if="item.data" class="product-sub-items-list" :class="{ dashed }">
        <li v-for="(subItem, subItemId) in productBundleSubItems" :key="subItemId">
          <div
            class="line-item d-flex flex-row"
            :class="{ dashed }"
          >
            <small class="product-info">
              {{ subItem.name }}
            </small>
            <hr v-if="dashed" class="line-of-dashed">
            <small
              class="product-price"
              :class="itemFree(subItem) ? 'strike-through-product-cost' : ''"
            >
              {{ subItem.price | dollars }}
            </small>
          </div>
        </li>
      </ul>
      <div
        v-for="(filingMethod, index) in productBundleFilingMethods"
        :key="index"
        class="line-item d-flex flex-row"
        :class="{ dashed }"
      >
        <span>
          {{ filingMethod.name }}
        </span>
        <hr v-if="dashed" class="line-of-dashed">
        <span>
          {{ filingMethod.price | dollars }}
        </span>
      </div>
    </div>

    <div v-if="item.display_info.product_category === 'free form a company'" class="d-flex flex-row justify-content-between">
      <div>
        6 Month Registered Agent Service <span v-if="itemState"> in {{ itemState }}</span>
      </div>
      <div>
        {{ 0 | dollars }}
      </div>
    </div>
  </div>
</template>

<script>
  import { dollars } from '@/filters'
  import FeatherIcon from '@/components/shared/FeatherIcon'
  import { mapGetters, mapActions } from 'vuex'
  import { GENERAL_OVERSEAS_SHIPPING_FEE_NAMES } from '@/common/modules/constants'

  export default {
    name: 'CartItem',

    filters: {
      dollars,
    },

    components: {
      FeatherIcon,
    },

    props: {
      item: Object,
      dashed: {
        type: Boolean,
        default: false,
      },
      showSubItems: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      ...mapGetters('checkout', [
        'productIsBundle',
        'sCorpInCart',
        'isFormationFiling',
        'productBundleTotal',
        'cartItemWithShipping',
        'overseasShippingFeeProduct',
        'cartItemWithForeignShippingAddress',
      ]),
      ...mapGetters('jurisdictions', ['findByAbbreviation']),
      ...mapGetters('lawOnCall', ['lawOnCallTrialEligible']),
      ...mapGetters('companies', ['domesticRegistrationMailingAddressIsForeign']),
      overseasShippingFeeProductDescription() {
        return 'Extra fee covers the shipping to your country and is required for international addresses. ' +
          'Additional customs fees may apply.'
      },
      isFreeTrial() {
        return this.item?.display_info?.is_free_trial || false
      },
      isMonthly() {
        return this.item.product_type === 'SubscriptionBundle'
      },
      itemState() {
        return this.item.data?.state
          || this.findByAbbreviation(this.item?.display_info?.jurisdiction_abbreviation)?.state_province_region
      },
      productBundleSubItems() {
        const subItems = []

        if (this.productIsBundle(this.item)) {
          for (const subItemId in this.item.data) {
            const subItem = this.item.data[subItemId]
            subItems.push({
              name: subItem.name,
              price: subItem.price,
              is_included_in_bundle_cost: subItem.is_included_in_bundle_cost || false,
              is_free_trial: subItem?.is_free_trial || false,
            })
          }
        }

        return subItems
      },
      productBundleFilingMethods() {
        const filingMethods = []

        if (this.productIsBundle(this.item)) {
          for (const subItemId in this.item.data) {
            const subItem = this.item.data[subItemId]

            if (subItem.kind === 'filing_product' && subItem.filing_method) {
              filingMethods.push({
                name: `${subItem.filing_method.jurisdiction} State Fee`,
                price: subItem.filing_method.cost,
              })
            }
          }
        }

        return filingMethods
      },
    },
    mounted() {
      if(this.item.display_info.product_category === 'law-on-call' && this.lawOnCallTrialEligible){
        this.item.total = 0
      }
    },
    methods: {
      ...mapActions('checkout', [
        'removeFromCart',
        'addToCart',
      ]),
      ...mapActions('stagelineSchemaForm', [
        'setRaAddressReset',
      ]),
      cartItemIsOverseasShippingFee(item) {
        return GENERAL_OVERSEAS_SHIPPING_FEE_NAMES.includes(item.title.toLowerCase())
      },
      async removeItem() {
        const route = this.$router.currentRoute

        if (route.name === 'checkout-required-info' || route.name === 'checkout-payment') {
          if (route.params.productId === this.item.product_id) {
            alert('Cannot delete this item from this page.')
          } else if (this.item.data && this.item.data.added_from_filing) {
            alert('Cannot delete this item because a filing is dependent on it.')
          } else {
            await this.removeFromCart(this.item)
            if (this.item.product_type === 'RegisteredAgentProduct') await this.setRaAddressReset(true)
            if (this.isFormationFiling) {
              const cartHasSCorp = this.sCorpInCart
              if (cartHasSCorp) await this.removeFromCart(cartHasSCorp)
            }
          }
        } else {
          await this.removeFromCart(this.item)
          if (this.item.product_type === 'RegisteredAgentProduct') await this.setRaAddressReset(true)
          if (this.isFormationFiling) {
            const cartHasSCorp = this.sCorpInCart
            if (cartHasSCorp) await this.removeFromCart(cartHasSCorp)
          }
        }
        // Check if overseas shipping fee needs to be re-added after parent item removal
        await this.checkForOverseasShippingFee()
      },

      async checkForOverseasShippingFee() {
        const overseasDelivery = this.cartItemWithForeignShippingAddress ? true : this.domesticRegistrationMailingAddressIsForeign
        const parentItem = this.cartItemWithShipping
        if (overseasDelivery && parentItem) await this.addToCart({ ...this.overseasShippingFeeProduct, parentItemId: parentItem.id })
      },

      displayProductName(item) {
        if (item.title.toLowerCase().includes('online federal tax id')) {
          return 'Federal Tax ID - Client has SSN'
        } else if (item.title.toLowerCase().includes('paper federal tax id')) {
          return 'Federal Tax ID - Client Doesn\'t Have SSN'
        } else {
          return item.title
        }
      },
      itemFree(subItem) {
        return (subItem?.is_included_in_bundle_cost || subItem?.is_free_trial) || false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .cart-item {
    font-size: .9rem;
    text-align: left;

    .line-item:not(.dashed) {
      justify-content: space-between;
    }

    .line-item.dashed {
      align-items: baseline;

      .line-of-dashed {
        border-top: 1px dashed #707070;
        flex: 1;
        margin: 0 0.5rem;
      }
    }
  }

  .price-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
  }

  .remove-button-container {
    width: 1em;
    height: 1em;
    margin: 0 0.5em 0.5em;

    .remove-button {
      pointer-events: auto;
      color: $danger;
    }
  }
  .badge-success {
    background: $ct-ui-color-11;
    font-size: .9em;
  }

  span.promo-code {
    color: #14A40E;
    white-space: nowrap;
  }

  .product-info {
    float: left;
    margin-right: 0.625em; /* Adjust as needed */
  }

  .product-price {
    float: right;
  }
  .strike-through-product-cost {
    text-decoration: line-through;
    text-decoration-color: #F45151;
  }
  .product-sub-items-list {
    margin-bottom: 0;
  }
</style>
